import { StrictMode } from 'react';
import { defineCustomElements, GenDesignSystemProvider } from '@genetec/gelato-react';
import ReactDOM from 'react-dom/client';

import { App } from 'src/App';

import 'src/scss/index.scss';

window.streamVaultAppConfigurations = window.streamVaultAppConfigurations || {};

defineCustomElements();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <GenDesignSystemProvider useTopLevelStyles={true}>
      <App />
    </GenDesignSystemProvider>
  </StrictMode>,
);
