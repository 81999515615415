import { FC, lazy, Suspense } from 'react';
import { GenSpinner, GenText } from '@genetec/gelato-react';

export const App: FC = () => {
  const LazyHeavyApp = lazy(() => import('./HeavyApp'));

  return (
    <Suspense
      fallback={
        <GenSpinner>
          <GenText>... Loading</GenText>
        </GenSpinner>
      }
    >
      <LazyHeavyApp />
    </Suspense>
  );
};
